import * as React from "react";

function SvgPresent(props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="48" width="48"   viewBox="0 0 48 48" {...props} fill={'currentColor'}>
            <path
                d="M7 34.15V39Q7 39 7 39Q7 39 7 39H41Q41 39 41 39Q41 39 41 39V34.15ZM7 42Q5.75 42 4.875 41.125Q4 40.25 4 39V15Q4 13.75 4.875 12.875Q5.75 12 7 12H13.25Q13 11.55 12.85 10.875Q12.7 10.2 12.7 9.65Q12.7 7.3 14.35 5.65Q16 4 18.3 4Q19.85 4 21.15 4.775Q22.45 5.55 23.2 6.75L24.05 8.05L24.85 6.75Q25.65 5.5 26.9 4.75Q28.15 4 29.6 4Q32 4 33.65 5.625Q35.3 7.25 35.3 9.65Q35.3 10.2 35.175 10.75Q35.05 11.3 34.8 12H41Q42.25 12 43.125 12.875Q44 13.75 44 15V39Q44 40.25 43.125 41.125Q42.25 42 41 42ZM7 28.8H41V15Q41 15 41 15Q41 15 41 15H28.55L32.7 20.75Q33.05 21.25 32.925 21.825Q32.8 22.4 32.35 22.85Q31.85 23.25 31.225 23.175Q30.6 23.1 30.25 22.55L24 13.85L17.7 22.55Q17.3 23.1 16.675 23.175Q16.05 23.25 15.65 22.85Q15.15 22.45 15.075 21.875Q15 21.3 15.35 20.75L19.45 15H7Q7 15 7 15Q7 15 7 15ZM18.35 12.3Q19.45 12.3 20.225 11.525Q21 10.75 21 9.65Q21 8.55 20.225 7.775Q19.45 7 18.35 7Q17.25 7 16.475 7.775Q15.7 8.55 15.7 9.65Q15.7 10.75 16.475 11.525Q17.25 12.3 18.35 12.3ZM29.6 12.3Q30.75 12.3 31.525 11.525Q32.3 10.75 32.3 9.65Q32.3 8.55 31.525 7.775Q30.75 7 29.6 7Q28.55 7 27.775 7.775Q27 8.55 27 9.65Q27 10.75 27.775 11.525Q28.55 12.3 29.6 12.3Z"/>
        </svg>
    );
}

export default SvgPresent;
